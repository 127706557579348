import React from 'react';
import {Box} from "@mui/material";

interface IExpertiseWidgetInput {

}

const ExpertiseWidget = ({}: IExpertiseWidgetInput) => {


    return (
        <Box>
            test
        </Box>
    )
}

export default ExpertiseWidget