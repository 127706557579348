import React from 'react'

const PageFooter = () => {
    return (
        <div className="PageFooter">

        </div>
    )
}

export default PageFooter