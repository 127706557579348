import React from 'react';
import {Box} from "@mui/material";

interface ICreateUserPageInput {

}

const CreateUserPage = ({}: ICreateUserPageInput) => {


    return (
        <Box>
            test
        </Box>
    )
}

export default CreateUserPage