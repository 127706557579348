import React from 'react';

const Page404 = () => {
    return (
        <div className={"Page404"}>
            404
        </div>
    )
}

export default Page404