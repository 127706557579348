import React from 'react';
import {Box} from "@mui/material";

interface IDowntimeTabInput {

}

const DowntimeTab = ({}: IDowntimeTabInput) => {


    return (
        <Box>
            test
        </Box>
    )
}

export default DowntimeTab